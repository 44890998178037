import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC, useMemo } from "react";
import styles from "./CoursesBlock.module.scss";
import Slider, { Settings } from "react-slick";
import { ICourse } from "@types";
import CourseCard from "components/cards/CourseCard";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import clsx from "clsx";

interface IArrowProps {
  onClick: any;
}

const NextArrow: FC<IArrowProps> = ({ onClick }) => {
  return (
    <Box
      className={clsx(styles.slider__arrow, styles.slider__arrow_next)}
      onClick={onClick}
    >
      <ArrowForwardIosRoundedIcon />
    </Box>
  );
};

const PrevArrow: FC<IArrowProps> = ({ onClick }) => {
  return (
    <Box
      className={clsx(styles.slider__arrow, styles.slider__arrow_prev)}
      onClick={onClick}
    >
      <ArrowBackIosNewRoundedIcon />
    </Box>
  );
};

interface ICoursesBlockProps {
  title: string;
  courseList: ICourse[];
}

const CoursesBlock: FC<ICoursesBlockProps> = ({ title, courseList }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const sliderSettings: Settings = useMemo(() => {
    return {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: matchesSM ? 1 : 4,
      slidesToScroll: 1,
      // @ts-ignore
      prevArrow: <PrevArrow />,
      // @ts-ignore
      nextArrow: <NextArrow />,
      centerMode: matchesSM,
      centerPadding: "24px",
    };
  }, [matchesSM]);

  return (
    <div className={styles.root}>
      <Typography variant="h3">{title}</Typography>

      <Slider {...sliderSettings} className={styles.slider}>
        {courseList.map((course) => (
          <CourseCard
            key={course.id}
            course={course}
            inMobileCarousel={matchesSM}
          />
        ))}
      </Slider>
    </div>
  );
};

export default CoursesBlock;
