import { Box } from "@mui/material";
import Footer from "components/Footer";
import Header from "components/Header";
import { FC, ReactNode } from "react";
import styles from "./Layout.module.scss";

interface ILayoutProps {
  children: ReactNode | ReactNode[];
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  return (
    <Box className={styles.layout}>
      <Header />

      <Box className={styles.children}>{children}</Box>

      <Footer />
    </Box>
  );
};

export default Layout;
