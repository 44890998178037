import {
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from "react-redux";
import { AppDispatch, AppState } from "store";

export const useDispatch = () => useDispatchBase<AppDispatch>();

export const useSelector = <TSelected = unknown>(
  selector: (state: AppState) => TSelected
): TSelected => useSelectorBase<AppState, TSelected>(selector);
