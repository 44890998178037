import {
  Box,
  Typography,
  SelectChangeEvent,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useState, useId, useEffect } from "react";
import styles from "./CourseCatalog.module.scss";
import CheckboxValue from "components/inputs/CheckboxValue";
import FilterAccordion from "components/FilterAccordion";
import CourseCard from "components/cards/CourseCard";
import { ICategory, ICourse } from "@types";
import { getCourseListAPI } from "api/course.api";
import { useTranslation } from "next-i18next";

interface ICourseCatalogProps {
  categoryList: ICategory[];
  courseList: ICourse[];
}

const CourseCatalog: FC<ICourseCatalogProps> = ({
  categoryList,
  courseList,
}) => {
  const { t } = useTranslation("common");
  const prefix = useId();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);

  const [_courseList, setCourseList] = useState<ICourse[]>(courseList);

  // const [sortBy, setSortBy] = useState(0);
  const [categories, setCategories] = useState<number[]>([]);
  const [free, setFree] = useState(false);
  const [paid, setPaid] = useState(false);
  const [hasCertificate, setHasCertificate] = useState(false);

  const handleChangeCategory = (categoryId: number) => {
    if (categories.includes(categoryId)) {
      setCategories(categories.filter((n) => n !== categoryId));
      return;
    }

    setCategories([...categories, categoryId]);
  };

  // const handleChangeSort = (event: SelectChangeEvent<number>) => {
  //   setSortBy(event.target.value as number);
  // };

  useEffect(() => {
    setLoading(true);
    getCourseListAPI({
      categories: `[${categories.toString()}]`,
      free,
      paid,
      has_certificate: hasCertificate,
    })
      .then((data) => {
        setCourseList(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [categories, free, paid, hasCertificate]);

  return (
    <Box id="catalog" className={styles.root}>
      <Box className={styles.root__heading}>
        <Typography className={styles.root__heading__title}>
          {t("course_catalog")}
        </Typography>

        {/* <Box className={styles.root__heading__control}>
                    <Typography>
                        Сортировать по
                    </Typography>

                    <Select
                        value={sortBy}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        <MenuItem value={0}>С наивысшим рейтингом</MenuItem>
                    </Select>
                </Box> */}
      </Box>

      <Box className={styles.root__content}>
        <Box className={styles.root__content__filter}>
          <Box className={styles.root__content__filter__heading}>
            {t("filter")}
          </Box>

          <Box className={styles.root__content__filter__categories}>
            <FilterAccordion summary={t("categories")} defaultExpanded>
              {categoryList.map((category) => (
                <CheckboxValue
                  key={`${prefix}-${category.id}`}
                  checked={categories.includes(category.id)}
                  label={category.title}
                  onChange={(_: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeCategory(category.id)
                  }
                />
              ))}
            </FilterAccordion>
          </Box>
          <Box className={styles.root__content__filter__price}>
            <FilterAccordion summary={t("price")} defaultExpanded>
              <CheckboxValue
                checked={free}
                label={t("free_other")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFree(event.target.checked);
                }}
              />
              <CheckboxValue
                checked={paid}
                label={t("paid_other")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPaid(event.target.checked);
                }}
              />
            </FilterAccordion>
          </Box>
          <Box className={styles.root__content__filter__certificate}>
            <CheckboxValue
              checked={hasCertificate}
              label={t("with_certificates")}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setHasCertificate(event.target.checked);
              }}
            />
          </Box>
        </Box>

        {loading ? (
          <Box className={styles.root__content__spacer}>
            <CircularProgress color="primary" />
          </Box>
        ) : _courseList.length ? (
          <Box className={styles.root__content__list}>
            {_courseList
              .filter((course) => course.is_active)
              .map((course) => (
                <CourseCard
                  key={`${prefix}-${course.id}`}
                  course={course}
                  horizontal={!matchesSM}
                />
              ))}
          </Box>
        ) : (
          <Box className={styles.root__content__spacer}>
            <Typography align="center" variant="h5" fontWeight={700}>
              {t("no_suitable_courses")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CourseCatalog;
