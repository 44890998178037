import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  useTheme,
  FormHelperText,
} from "@mui/material";
import React, { FC, ReactNode } from "react";

interface ICheckboxValueProps {
  value?: unknown;
  checked?: boolean;
  label: ReactNode;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: ReactNode;
}

const CheckboxValue: FC<ICheckboxValueProps> = ({
  value,
  checked,
  label,
  name,
  onChange,
  helperText,
}) => {
  const theme = useTheme();

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            sx={{ color: theme.palette.primary.main }}
          />
        }
        label={label}
        sx={{ alignSelf: "flex-start" }}
      />
      <FormHelperText disabled={!helperText}>{helperText}</FormHelperText>
    </FormGroup>
  );
};

export default CheckboxValue;
