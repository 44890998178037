import { Box, Typography } from "@mui/material";
import Image from "next/image";
import { FC } from "react";
import styles from "./Footer.module.scss";
import LogoPath from "assets/logo.svg";
import FacebookIconPath from "assets/icons/fb.svg";
import LinkedinIconPath from "assets/icons/linkedin.svg";
import TelegramGIconPath from "assets/icons/telegram.svg";
import InstagramIconPath from "assets/icons/ig.svg";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { privacyPolicyURL, userAgreementURL } from "utils/constants";

const Footer: FC = () => {
  const { t } = useTranslation("common");

  return (
    <Box component="footer" className={styles.footer}>
      <Box className={styles.footer__main}>
        <Box className={styles.footer__main__content}>
          <Image src={LogoPath} alt="logo" width={72} height={32} />

          {/* <Box className={styles.footer__main__col}>
            <Typography>JVA Business</Typography>
            <Typography>{t("teach_on_jva")}</Typography>
            <Typography>{t("about")}</Typography>
          </Box> */}

          <Box
            className={clsx(
              styles.footer__main__col,
              styles.footer__main__rules
            )}
          >
            <Typography>{t("rules")}</Typography>
            <a
              href={userAgreementURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("user_agreement")}
            </a>
            <a
              href={privacyPolicyURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("privacy_policy")}
            </a>
          </Box>

          <Box className={styles.footer__main__social}>
            <a
              href="https://www.facebook.com/Jas-Ventures-Almaty-107199215261573"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={FacebookIconPath} alt="fb" width={24} height={24} />
            </a>
            <a
              href="https://www.linkedin.com/in/jas-ventures-64b204235"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={LinkedinIconPath}
                alt="linkedin"
                width={24}
                height={24}
              />
            </a>
            <a
              href="https://t.me/jas_ventures"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={TelegramGIconPath}
                alt="telegram"
                width={24}
                height={24}
              />
            </a>
            <a
              href="https://www.instagram.com/jas.ventures"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={InstagramIconPath} alt="ig" width={24} height={24} />
            </a>
          </Box>
        </Box>
      </Box>
      <Box className={styles.footer__brand}>
        ©{new Date().getFullYear()} JAS Ventures Almaty
      </Box>
    </Box>
  );
};

export default Footer;
