import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ICourse } from "@types";
import Image from "next/image";
import { FC, useRef } from "react";
import styles from "./CourseCard.module.scss";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import LockIconPath from "assets/icons/lock.svg";
import clsx from "clsx";
import Link from "next/link";
import CustomRating from "components/CustomRating";
import { useTranslation } from "next-i18next";
import { defaultCoverPlaceholderURL } from "utils/constants";

interface ICourseCardProps {
  course: ICourse;
  horizontal?: boolean;
  inMobileCarousel?: boolean;
}

const CourseCard: FC<ICourseCardProps> = ({
  course,
  horizontal,
  inMobileCarousel,
}) => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const imageURL = course.cover || defaultCoverPlaceholderURL;
  const cardRef = useRef<HTMLDivElement>();

  return (
    <Link href={`/about-course/${course.id}`}>
      <a className={styles.link}>
        <Box
          ref={cardRef}
          className={clsx(styles.root, {
            [styles.root_horizontal]: horizontal,
            [styles.root_mobile_carousel]: inMobileCarousel,
          })}
        >
          <Box
            className={clsx(
              !matchesSM
                ? styles.root__image
                : clsx(styles.root__image, styles.root__image_mobile_wrapper),
              { [styles.root__image_horizontal]: horizontal }
            )}
          >
            <Image src={imageURL} alt="course-cover" layout="fill" />

            {course.manual_access && (
              <Box className={styles.root__lock}>
                <Image src={LockIconPath} alt="lock" width={20} height={20} />
              </Box>
            )}
          </Box>

          <Box
            className={clsx(styles.root__content, {
              [styles.root__content_horizontal]: horizontal,
            })}
          >
            <Box flexGrow={1}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  className={clsx(styles.root__title, {
                    [styles.root__title_horizontal]: horizontal,
                  })}
                  title={course.title}
                >
                  {course.title}
                </Typography>
              </Box>

              {horizontal && (
                <>
                  <Box
                    className={clsx(styles.root__info, {
                      [styles.root__info_horizontal]: horizontal,
                    })}
                  >
                    {t("total")} {course.duration_in_hours} {t("hour_other")} •{" "}
                    {course.lessons_count} {t("lesson_other")} •{" "}
                    {t(course.level.toLowerCase())} {t("level")}
                  </Box>

                  <Box className={styles.root__desc}>
                    {course.short_description}
                  </Box>
                </>
              )}

              <Box className={styles.root__author}>
                {course.author?.avatar_url && (
                  <Avatar
                    sx={{ width: 16, height: 16 }}
                    src={course.author?.avatar_url}
                  />
                )}
                <Typography>
                  {course.author?.first_name} {course.author?.last_name}
                </Typography>
              </Box>

              <CustomRating value={course.rating} horizontal={horizontal} />

              {!horizontal && (
                <>
                  <Box className={styles.root__info}>
                    {t("total")} {course.duration_in_hours} {t("hour_other")} •{" "}
                    {course.lessons_count} {t("lesson_other")} •{" "}
                    {t(course.level.toLowerCase())} {t("level")}
                  </Box>

                  <Box
                    className={clsx(styles.root__price, {
                      [styles.root__price_free]: !course.price,
                    })}
                  >
                    {course.price ? `${course.price} KZT` : t("free_")}
                  </Box>
                </>
              )}
            </Box>

            {horizontal && (
              <Box
                className={clsx(styles.root__price, {
                  [styles.root__price_horizontal]: horizontal,
                  [styles.root__price_free]: !course.price,
                })}
              >
                {course.price ? `${course.price} KZT` : t("free_")}
              </Box>
            )}
          </Box>

          {/* <Box className={styles.root__favourite}>
                        {course.favourite ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />}
                    </Box> */}
        </Box>
      </a>
    </Link>
  );
};

export default CourseCard;
