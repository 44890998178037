import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { FC, ReactNode } from "react";
import styles from "./FilterAccordion.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IFilterAccordion {
  summary: string;
  children: ReactNode | ReactNode[];
  defaultExpanded?: boolean;
}

const FilterAccordion: FC<IFilterAccordion> = ({
  summary,
  children,
  defaultExpanded,
}) => {
  return (
    <Accordion
      classes={{
        root: styles.accordion__root,
        expanded: styles.accordion__expanded,
      }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        classes={{
          root: styles.accordion_summary__root,
          expanded: styles.accordion_summary__expanded,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.accordion_details__root,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterAccordion;
