export const mockSkillList = [
  "Android Development",
  "AutoCad",
  "Big Data",
  "Blockchain",
  "Business analytics",
  "Business process automation",
  "C++/C#",
  "Communications and public speaking",
  "Computer networks",
  "Computer Vision",
  "Cross-Platform Development",
  "Data mining engineering",
  "Data Science",
  "Developers",
  "Django",
  "Economics",
  "Finance",
  "HTML/CSS",
  "IOS Development",
  "IT leadership",
  "JavaScript",
  "Macroeconomics",
  "Management",
  "Marketing",
  "Network management",
  "Neural network",
  "PHP",
  "Problem solving",
  "Programming",
  "Project management",
  "Python",
  "Robotics",
  "SQL",
  "Ui / UX",
  "VR",
  "Web-design",
  "React JS",
];
