import { Box, Rating, Typography } from "@mui/material";
import { FC } from "react";
import styles from "./CustomRating.module.scss";
import clsx from "clsx";

interface ICustomRatingProps {
  value: number;
  reviews?: number;
  horizontal?: boolean;
}

const CustomRating: FC<ICustomRatingProps> = ({
  value,
  reviews,
  horizontal,
}) => {
  const value_ = Math.round((value / 2) * 10) / 10 || 0;

  return (
    <Box
      className={clsx(styles.custom_rating, {
        [styles.custom_rating_horizontal]: horizontal,
      })}
    >
      <Typography>{value_}</Typography>

      <Rating
        value={value_}
        readOnly
        precision={0.5}
        sx={{ "& .MuiRating-iconEmpty .MuiSvgIcon-root": { color: "#FFC107" } }}
      />

      <Typography className={styles.custom_rating__reviews}>
        {reviews} оценок
      </Typography>
    </Box>
  );
};

export default CustomRating;
