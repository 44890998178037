import type { GetServerSideProps, NextPage } from "next";
import styles from "styles/Home.module.scss";
import { Avatar, Box, Button, Typography } from "@mui/material";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import Image from "next/image";
import Head from "next/head";
import DemoImgPath from "assets/images/demo.png";
import GearIconPath from "assets/icons/gear.svg";
import MonitorIconPath from "assets/icons/monitor.svg";
import ServiceIconPath from "assets/icons/service.svg";
import RewardIconPath from "assets/icons/reward.svg";
import ReactImgPath from "assets/images/react.png";
import PHPImgPath from "assets/images/php.png";
import JavaImgPath from "assets/images/java.png";
import { useId } from "react";
import CoursesBlock from "components/course/CourseBlock";
import CourseCatalog from "components/course/CourseCatalog";
import Layout from "containers/Layout";
import { ICategory, ICourse } from "@types";
import { getCategoryListAPI } from "api/common.api";
import { getCourseListAPI } from "api/course.api";
import { mockSkillList } from "utils/mocks";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { SITE_NAME } from "utils/constants";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

interface IHomeProps {
  categoryList: ICategory[];
  popularCourseList: ICourse[];
  freeCourseList: ICourse[];
  allCourseList: ICourse[];
}

const Home: NextPage<IHomeProps> = ({
  categoryList,
  popularCourseList,
  freeCourseList,
  allCourseList,
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const prefix = useId();
  const { data: session } = useSession();

  const handleClickStart = () => {
    router.push(session ? "/profile" : "/login");
  };

  return (
    <Layout>
      <Head>
        <title>{SITE_NAME}</title>
      </Head>

      <Box className={styles.first_section}>
        <Box className={styles.first_section__content}>
          <Box className={styles.first_section__content__start}>
            <Typography variant="h1">{t("educational_platform")}</Typography>
            <Typography>{t("slogan")}</Typography>

            <Button
              variant="contained"
              className={styles.first_section__content__start__btn}
              endIcon={<ArrowRightAltOutlinedIcon />}
              onClick={handleClickStart}
            >
              {t("start")}
            </Button>
          </Box>

          <Box className={styles.first_section__content__demo}>
            <Image src={DemoImgPath} alt="demo" layout="responsive" priority />
          </Box>
        </Box>
      </Box>

      <Box className={styles.stats}>
        <Box className={styles.stats__numbers}>
          <Box className={styles.stats__numbers__item}>
            <Typography variant="h3">22</Typography>
            <Typography>{t("online_courses")}</Typography>
          </Box>

          <Box className={styles.stats__numbers__item}>
            <Typography variant="h3">20+</Typography>
            <Typography>{t("trackers")}</Typography>
          </Box>

          <Box className={styles.stats__numbers__item}>
            <Typography variant="h3">300+</Typography>
            <Typography>{t("graduates_per_year")}</Typography>
          </Box>

          <Box className={styles.stats__numbers__item}>
            <Typography variant="h3">500+</Typography>
            <Typography>{t("video_tutorials")}</Typography>
          </Box>
        </Box>

        <Box className={styles.stats__advantages}>
          <Typography
            variant="h3"
            align="center"
            className={styles.stats__advantages__heading}
          >
            {t("advantages")}
          </Typography>

          <Box className={styles.stats__advantages__row}>
            <Box className={styles.stats__advantages__col}>
              <Box>
                <Image src={MonitorIconPath} alt="monitor" />
              </Box>
              <Typography variant="h5" align="center">
                {t("convenience")}
              </Typography>
              <Typography align="center">{t("convenience_text")}</Typography>
            </Box>

            <Box className={styles.stats__advantages__col}>
              <Box>
                <Image src={ServiceIconPath} alt="service" />
              </Box>
              <Typography variant="h5" align="center">
                {t("support")}
              </Typography>
              <Typography align="center">{t("support_text")}</Typography>
            </Box>

            <Box className={styles.stats__advantages__col}>
              <Box>
                <Image src={RewardIconPath} alt="reward" />
              </Box>
              <Typography variant="h5" align="center">
                {t("expertise")}
              </Typography>
              <Typography align="center">{t("expertise_text")}</Typography>
            </Box>
          </Box>
        </Box>

        <Box className={styles.stats__reviews}>
          <Typography
            variant="h3"
            align="center"
            className={styles.stats__reviews__heading}
          >
            {t("our_students_say")}
          </Typography>

          <Box className={styles.stats__reviews__row}>
            <Box className={styles.stats__reviews__col}>
              <Box className={styles.stats__reviews__card}>
                <Typography variant="h5" align="center">
                  ReactJS {t("developer")}
                </Typography>
                <Typography align="center">{t("react_review")}</Typography>
              </Box>

              <Box className={styles.stats__reviews__student}>
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  src={ReactImgPath.src}
                ></Avatar>
                <Typography className={styles.stats__reviews__student__name}>
                  {t("react_name")}
                </Typography>
                <Typography
                  className={styles.stats__reviews__student__position}
                >
                  Junior ReactJS {t("developer")}
                </Typography>
              </Box>
            </Box>

            <Box className={styles.stats__reviews__col}>
              <Box className={styles.stats__reviews__card}>
                <Typography variant="h5" align="center">
                  PHP {t("developer")}
                </Typography>
                <Typography align="center">{t("php_review")}</Typography>
              </Box>

              <Box className={styles.stats__reviews__student}>
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  src={PHPImgPath.src}
                ></Avatar>
                <Typography className={styles.stats__reviews__student__name}>
                  {t("php_name")}
                </Typography>
                <Typography
                  className={styles.stats__reviews__student__position}
                >
                  Junior PHP {t("developer")}
                </Typography>
              </Box>
            </Box>

            <Box className={styles.stats__reviews__col}>
              <Box className={styles.stats__reviews__card}>
                <Typography variant="h5" align="center">
                  Java {t("developer")}
                </Typography>
                <Typography align="center">{t("java_review")}</Typography>
              </Box>

              <Box className={styles.stats__reviews__student}>
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  src={JavaImgPath.src}
                ></Avatar>
                <Typography className={styles.stats__reviews__student__name}>
                  {t("java_name")}
                </Typography>
                <Typography
                  className={styles.stats__reviews__student__position}
                >
                  Junior Java {t("developer")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.quote}>
        <Box className={styles.quote__layer1}></Box>
        <Box className={styles.quote__layer2}></Box>

        <Box className={styles.quote__content}>
          <Typography
            className={styles.quote__content__text}
            dangerouslySetInnerHTML={{ __html: t("abay_kunanbayev_quote") }}
          />
          <Typography className={styles.quote__content__author}>
            — {t("abay_kunanbayev")}
          </Typography>
        </Box>
      </Box>

      {(popularCourseList?.length > 3 || freeCourseList.length > 3) && (
        <Box className={styles.courses}>
          {popularCourseList?.length > 3 && (
            <CoursesBlock
              title={`${t("popular")} ${t("courses")}`}
              courseList={popularCourseList}
            />
          )}

          {freeCourseList?.length > 3 && (
            <CoursesBlock
              title={`${t("free_other")} ${t("courses")}`}
              courseList={freeCourseList}
            />
          )}
        </Box>
      )}

      <Box className={styles.catalog}>
        <CourseCatalog categoryList={categoryList} courseList={allCourseList} />
      </Box>

      <Box className={styles.skills}>
        <Box className={styles.skills__content}>
          <Typography
            variant="h3"
            align="center"
            className={styles.skills__heading}
          >
            {t("popular")} {t("skills")}
          </Typography>

          <Box className={styles.skills__list_wrapper}>
            <Box className={styles.skills__list}>
              {mockSkillList.slice(0, 13).map((skill, index) => (
                <Box
                  key={`${prefix}-${index}`}
                  className={styles.skills__list__item}
                >
                  {skill}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const categoryList = await getCategoryListAPI();
  const popularCourseList = await getCourseListAPI({ popular: true });
  const freeCourseList = await getCourseListAPI({ free: true });
  const allCourseList = await getCourseListAPI();

  return {
    props: {
      categoryList,
      popularCourseList,
      freeCourseList,
      allCourseList,
      ...(await serverSideTranslations(locale!, ["common"])),
    },
  };
};

const HomeNoSSR = dynamic(() => Promise.resolve(Home), {
  ssr: false,
});

export default HomeNoSSR;
