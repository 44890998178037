import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Image from "next/image";
import { FC, useEffect, useState } from "react";
import styles from "./Header.module.scss";
import LogoPath from "assets/logo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Link from "next/link";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { useTranslation } from "next-i18next";
import { NextRouter, useRouter } from "next/router";
import { INavigationLesson, TLocale } from "@types";
import { useSession, signOut } from "next-auth/react";
import { deleteCookie } from "cookies-next";
import { styled } from "@mui/material/styles";
import { logout } from "store/auth/slice";
import { useDispatch, useSelector } from "services/hook.service";
import { getUserDataThunk } from "store/auth/thunk";
import clsx from "clsx";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { completeLessonThunk } from "store/lesson/thunk";

const reviewPagePathname = "/p/course/[courseId]/review";
const learningPagePathname =
  "/p/course/[courseId]/module/[moduleId]/lesson/[lessonId]";

const checkLearningPage = (router: NextRouter) => {
  return [reviewPagePathname, learningPagePathname].includes(router.pathname);
};

const CustomSelect = styled(Select)((props) => ({
  "&&& .MuiInputBase-input": {
    [props.theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    [props.theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
}));

const Header: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const router = useRouter();
  const { data: session } = useSession();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useSelector((state) => state.auth.user);

  const lesson = useSelector((state) => state.lesson.currentLesson);

  const [locale, setLocale] = useState(router.locale);

  const isLearningPage = checkLearningPage(router);
  const isReviewPage = router.pathname === reviewPagePathname;

  const handleChangeLocale = (event: SelectChangeEvent) => {
    router.push(
      // @ts-ignore
      { router: router.pathname, query: router.query },
      router.asPath,
      { locale: event.target.value }
    );

    setLocale(event.target.value as TLocale);
  };

  const navigateLesson = (navLesson: INavigationLesson) => {
    const { course, module, id } = navLesson;

    const currentCourseId = Number(router.query.courseId);
    const currentModuleId = Number(router.query.moduleId);
    const currentLessonId = Number(router.query.lessonId);

    if (lesson?.is_passed) {
      router.push(`/p/course/${course}/module/${module}/lesson/${id}`);
      return;
    }

    dispatch(
      completeLessonThunk({
        courseId: currentCourseId,
        moduleId: currentModuleId,
        lessonId: currentLessonId,
      })
    ).then(() => {
      router.push(`/p/course/${course}/module/${module}/lesson/${id}`);
    });
  };

  const handleClickNextButton = () => {
    if (lesson?.next_lesson) {
      navigateLesson(lesson?.next_lesson!);
    } else {
      router.push(`/p/course/${router.query.courseId}/review`);
    }
  };

  const handleClickAuthButton = () => {
    if (session) {
      dispatch(logout());
      deleteCookie("access_token");
      deleteCookie("refresh_token");
      signOut();
      router.push("/");
    } else {
      router.push("/login");
    }
  };

  useEffect(() => {
    session && dispatch(getUserDataThunk());
  }, [session, dispatch]);

  return (
    <Box component="header" className={styles.header}>
      <Box
        className={clsx(styles.header__content, {
          [styles.header__content_wide]: isLearningPage,
        })}
      >
        <Box className={styles.header__content__links}>
          <Link href="/">
            <a
              className={clsx(styles.header__content__links__logo, {
                [styles.header__content__links__logo_with_paddings]:
                  isLearningPage,
              })}
            >
              <Image src={LogoPath} alt="logo" width={72} height={32} />
            </a>
          </Link>

          {!isLearningPage && (
            <Link href="/#catalog">
              <a className={styles.header__content__links__item}>
                {t("catalog")}
              </a>
            </Link>
          )}
        </Box>

        {isLearningPage && (
          <Box className={styles.header__content__course_controls}>
            <Box>
              {lesson?.prev_lesson && (
                <Button
                  className={styles.header__content__course_controls__button}
                  onClick={() => navigateLesson(lesson?.prev_lesson!)}
                  disabled={!lesson?.prev_lesson}
                >
                  <ArrowRightAltIcon sx={{ transform: "rotate(180deg)" }} />
                  &nbsp;
                  {t("back")}
                </Button>
              )}

              <Link href={`/about-course/${router.query.courseId}`}>
                <a
                  className={
                    styles.header__content__course_controls__course_name
                  }
                >
                  {lesson?.course_title}
                </a>
              </Link>
            </Box>

            {lesson?.next_lesson && (
              <Button
                className={styles.header__content__course_controls__button}
                onClick={handleClickNextButton}
              >
                {t("next")}
                &nbsp;
                <ArrowRightAltIcon />
              </Button>
            )}
          </Box>
        )}

        {isLearningPage && <Box className={styles.header__dummy} />}

        <Box
          className={clsx(styles.header__content__controls, {
            [styles.header__content__controls_with_border]: isLearningPage,
          })}
        >
          <CustomSelect
            value={locale}
            // @ts-ignore
            onChange={handleChangeLocale}
            IconComponent={KeyboardArrowDownIcon}
            sx={{ height: 40 }}
          >
            <MenuItem value={"ru"}>RU</MenuItem>
            <MenuItem value={"kk"}>KK</MenuItem>
            <MenuItem value={"en"}>EN</MenuItem>
          </CustomSelect>

          {session ? (
            <IconButton
              className={styles.header__content__controls__button}
              onClick={() => router.push("/profile")}
            >
              <Avatar alt="avatar" src={user?.avatar_url}>
                {user?.first_name[0]}
                {user?.last_name[0]}
              </Avatar>
            </IconButton>
          ) : !matchesSM ? (
            <Button
              className={styles.header__content__controls__button}
              onClick={handleClickAuthButton}
              startIcon={
                !session && <PersonOutlineRoundedIcon fontSize="small" />
              }
            >
              {t("sign_in")}
            </Button>
          ) : (
            <IconButton onClick={handleClickAuthButton}>
              <PersonOutlineRoundedIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
